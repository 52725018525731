<template>
  <div class="questionnaire-content">
    <el-form :model="searchForm" label-width="125px" :inline="true">
      <el-form-item label="问卷">
        <el-select v-model="searchForm.paperId" class="width-220" clearable>
          <el-option v-for="{ name, id } in papersData" :key="id" :value="id" :label="name">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="调查用户">
        <el-input v-model="searchForm.userName" class="width-220">
        </el-input>
      </el-form-item>
      <el-form-item label="开始时间">
        <el-date-picker v-model="searchForm.startTime" class="width-220" type="date" clearable>
        </el-date-picker>
      </el-form-item>
      <el-form-item label="结束时间">
        <el-date-picker v-model="searchForm.endTime" class="width-220" type="date" clearable>
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="searchInitFn">查找</el-button>
        <el-button @click="ExportFn">导出</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="tableData" v-loading="tableLoading"
      style="width: 100%; margin-bottom: 22px;">
      <el-table-column type="index" :index="setIndexFn" label="序号" width="50">
      </el-table-column>
      <el-table-column prop="username" label="评论用户">
      </el-table-column>
      <el-table-column prop="name" label="问卷">
      </el-table-column>
      <el-table-column prop="create_time" label="时间" width="150">
        <template slot-scope="scope">
          {{timStampToChar(scope.row.create_time)}}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="showFn(scope.row)">查看评论</el-button>
          <el-button type="text" @click="deleteFn(scope.row)">移除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      @size-change="sizeChange"
      @current-change="currentChange"
      :current-page="pagination.current"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pagination.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total">
    </el-pagination>
    <el-dialog
      title="查看评论" width="560px"
      @close="dialogVisible = false"
      v-loading="dialogLoading"
      :append-to-body="true"
      :visible.sync="dialogVisible">
      <div class="focus" v-if="current">
        <div>用户名称：{{current.username}}</div>
        <div>问卷名称：{{current.name}}</div>
      </div>
      <el-form
        :model="dialogForm" :disabled="true"
        ref="dialogForm">
        <template v-for="(value, key, index) in dialogForm">
          <el-form-item :label="`问题${index + 1}: ${questions[key]}`" :prop="key" :key="key">
            <el-input type="textarea" v-model="dialogForm[key]" style="width: 100%"></el-input>
          </el-form-item>
        </template>
      </el-form>
      <div slot="footer"></div>
    </el-dialog>
  </div>
</template>

<script>
import research from '@/api/research';
import papers from '@/api/papers';
import comments from '@/api/comments';
import { timStampToChar, timeToChar } from '@/utils/common';

export default {
  data() {
    return {
      tableLoading: false,
      dialogLoading: false,
      dialogVisible: false,
      tableData: [],
      papersData: [],
      questions: {},
      current: {},
      obj: {
        True: '正确',
        False: '错误',
      },
      searchForm: {
        paperId: '',
        userName: '',
        startTime: '',
        endTime: '',
      },
      dialogForm: {
        question1: '',
        question2: '',
        question3: '',
        question4: '',
      },
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
    };
  },
  watch: {
    dialogVisible(val) {
      if (!val) {
        this.$refs.dialogForm.resetFields();
        this.current = null;
      }
    },
    current: {
      deep: true,
      handler(val) {
        if (!val) return;
        Object.keys(this.dialogForm).forEach((key) => {
          this.dialogForm[key] = val.answer[key];
        });
      },
    },
  },
  mounted() {
    this.searchPapersFn();
    this.searchQuestionFn();
    this.searchFn();
  },
  methods: {
    timStampToChar,
    searchFn() {
      this.tableLoading = true;
      const { startTime, endTime, ...form } = this.searchForm;
      research.get({
        startTime: timeToChar(startTime),
        endTime: timeToChar(endTime, 'YYYY-MM-DD 23:59:59'),
        ...form,
        ...this.pagination,
      }).then(({ data, total }) => {
        this.tableLoading = false;
        this.tableData = data.data;
        this.pagination.total = total ? Number(total) : 0;
      }).catch(() => {
        this.tableLoading = false;
        this.tableData = [];
        this.pagination.total = 0;
      });
    },
    searchPapersFn() {
      papers.get({
        current: 1,
        pagesize: -1,
      }).then(({ data }) => {
        this.papersData = data.data;
      });
    },
    searchQuestionFn() {
      comments.question().then(({ data }) => {
        this.questions = data;
      });
    },
    searchInitFn() {
      this.pagination.current = 1;
      this.searchFn();
    },
    ExportFn() {
      const { userName, paperId } = this.searchForm;
      research.export({
        userName,
        paperId,
      }).then(({ data }) => {
        // window.location = `http://47.94.225.94${data}`;
        window.location = `${window.location.origin}${data}`;
      }).catch(() => {
        this.$message.error('导出失败');
      });
    },
    showFn({ id }) {
      this.dialogLoading = true;
      this.dialogVisible = true;
      research.getDetail(id).then(({ data }) => {
        this.dialogLoading = false;
        if (data && data.length) [this.current] = data;
      }).catch(() => {
        this.dialogLoading = false;
      });
    },
    deleteFn({ id }) {
      this.$confirm('确定要删除此条评论调查吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.tableLoading = true;
        research.delete(id).then(() => {
          this.tableLoading = false;
          this.searchInitFn();
          this.$message.success('评论调查删除成功');
        }).catch(() => {
          this.tableLoading = false;
          this.$message.error('评论调查删除失败');
        });
      }).catch(() => {
        this.$message.info('已取消删除评论调查');
      });
    },
    setIndexFn(index) {
      const { current, pageSize } = this.pagination;
      return (current - 1) * pageSize + index + 1;
    },
    sizeChange(val) {
      this.pagination.current = 1;
      this.pagination.pageSize = val;
      this.searchFn();
    },
    currentChange(val) {
      this.pagination.current = val;
      this.searchFn();
    },
  },
};
</script>

<style scoped lang="less">
  .focus {
    display: flex;
    line-height: 36px;
    div {
      width: 50%;
    }
  }
</style>
